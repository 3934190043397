import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "./components/Layout/Layout.lazy";
import ToyList from "./components/ToyList/ToyList.lazy";
import ToyLoader from "./components/ToyLoader/ToyLoader.lazy";

//provisional imports
//-----------------------
import Uno from "./toys/uno/uno.lazy";
//-----------------------

function App() {
  const useList = false;
  const routes = [
    { id: 1, to: 'uno', name: 'Uno'},
    { id: 2, to: 'stations', name: 'Stations'},
    { id: 3, to: 'calls', name: 'Calls'},
    { id: 4, to: 'elevator', name: 'Elevator'},
    { id: 5, to: 'metro', name: 'Metro'}
  ];
  return (
    <Router>
      <Layout routes={routes} className="App">
        <Routes>
          <Route path="/:toyId" element={<ToyLoader/>} />
          <Route exact path="/" element={ useList ? <ToyList /> : <Uno />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
