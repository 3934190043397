import React, { lazy, Suspense } from 'react';

const LazyUno = lazy(() => import('./uno'));

const Uno = props => (
  <Suspense fallback={null}>
    <LazyUno {...props} />
  </Suspense>
);

export default Uno;
