import React, { lazy, Suspense } from 'react';

const LazyToyLoader = lazy(() => import('./ToyLoader'));

const ToyLoader = props => (
  <Suspense fallback={null}>
    <LazyToyLoader {...props} />
  </Suspense>
);

export default ToyLoader;
